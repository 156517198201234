export const OnDemandTableHeader = (): any => [
  { prop: "id", label: "ID", sortable: true, width: 100 },
  { prop: "summary", label: "SUMMARY", width: 200, sortable: false },
  {
    prop: "product",
    label: "PRODUCT",
    sortable: false,
    width: 100,
    filters: [],
  },
  { prop: "owner", label: "USER ID", sortable: false, width: 150 },
  { prop: "created_time", label: "CREATE TIME", sortable: true, width: 200 },
  {
    prop: "execution_status",
    label: "EXECUTION STATUS",
    sortable: false,
    width: 180,
    filters: [],
  },
  { prop: "job_type", label: "EXECUTION TYPE", width: 150 },
  { prop: "content", label: "RUNBOOK NAME", sortable: false, width: 150 },
  { prop: "private", label: "RUNBOOK TYPE", sortable: false, width: 150 },
  { prop: "metadata", label: "META DATA", sortable: false, width: 170 },
];

export const ScheduledTableHeader = (): any => [
  { prop: "id", label: "ID", sortable: true },
  { prop: "schedule_id", label: "SCHEDULE ID", width: 110 },
  { prop: "summary", label: "SUMMARY", width: 200, sortable: false },
  {
    prop: "product",
    label: "PRODUCT",
    sortable: false,
    width: 100,
    filters: [],
  },
  { prop: "owner", label: "USER ID", sortable: false, width: 150 },
  { prop: "content", label: "RUNBOOK NAME", sortable: false, width: 100 },
  { prop: "private", label: "RUNBOOK TYPE", sortable: false, width: 150 },
  { prop: "schedule_type", label: "SCHEDULE TYPE", width: 120 },
];

export const ScheduledSubTableHeader = (): any => [
  { prop: "execution_id", label: "EXECUTION ID", width: 110 },
  { prop: "created_time", label: "CREATED TIME", width: 200 },
  { prop: "execution_status", label: "EXECUTION STATUS", width: 180 },
];

export enum PeriodicalSubTableEntry {
  ViewWorkItem = "ViewWorkItem",
  ScheduledWorkItem = "ScheduledWorkItem",
}

export interface WORKITEM_FILTER_CRITERIA {
  JobType?: string;
  scheduledType?: any;
  scheduleId?: any;
  product?: string[];
  status?: string;
  app_name?: string;
  offset?: any;
  pageSize?: any;
  owner?: any;
  JobId?: string[] | null;
  summary?: string;
  createdTimeRange?: string[] | null;
  [x: string]: any;
}

export interface WORKITEM_PARAM_DEFINITION {
  _name: string;
  type: string;
  value: string;
  label: string;
  hidden: string;
  _secure: string;
  fileContent: string;
  dateFormat: string;
  isDate: string;
  regex?: string;
  description?: string;
  allowedValues?: string;
  allowedResourceTypes?: string;
  defaultResourceType?: string;
  allowedResourceNum?: string;
}
