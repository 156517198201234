export enum SCHEDULE_STATUS {
  Scheduled = "Scheduled",
  ScheduleSuccess = "ScheduleSuccess",
  ScheduleFailed = "ScheduleFailed",
  Canceled = "Canceled",
  Completed = "Completed",
}

export const tableTitle = (): any => [
  { prop: "schedule_id", label: "Schedule Id" },
  { prop: "content", label: "Runbook Name" },
  { prop: "product", label: "Product" },
  { prop: "start_time", label: "Start Time" },
  { prop: "status", label: "Status" },
];

export const periodicTableHeader = (): any => [
  { prop: "schedule_id", label: "Schedule Id" },
  { prop: "content", label: "Runbook Name" },
  { prop: "product", label: "Product" },
  { prop: "start_time", label: "Start Time" },
  { prop: "cron_expression", label: "Cron Expression" },
  { prop: "status", label: "Status" },
];

export const periodicSubTabHeader = (): any => [
  { prop: "schedule_id", label: "Schedule Id" },
  { prop: "start_time", label: "Start Time" },
  { prop: "status", label: "Status" },
];
